var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor",attrs:{"id":"vacancies"}},[_c('loader',{attrs:{"show":_vm.show}}),_c('sesion',{attrs:{"expiration":_vm.expiration}}),_c('Sidebar'),_c('v-container',{staticClass:"base text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"titulo ml-10 mt-5",attrs:{"no-gutters":""}},[_vm._v("VACANTES EN PROCESO")]),_c('v-row',{staticClass:"datos pa-0 mt-5",staticStyle:{"height":"auto !important"},attrs:{"no-gutters":""}},[_c('v-card',{staticStyle:{"width":"100%","background":"rgb(199, 195, 199) !important"}},[_c('v-data-table',{staticClass:"elevation-2 text--dark",attrs:{"headers":_vm.headers,"items":_vm.vacancies,"search":_vm.search,"loading-text":"Cargando datos... Por favor espere","items-per-page":5,"no-results-text":'No se encontraron incidencias',"no-data-text":'No hay datos',"footer-props":{
            'items-per-page-text': 'Vacantes por página',
            'items-per-page': [5, 10, 15],
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [5, 10],
            'loading-text': 'Obteniendo datos... Por favor espere',
            'no-data-text': 'No hay datos...',
            'no-results-text': 'No se encontraron incidencias',
          }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"#ffffff","icon":"warning"}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("No hay datos en sistema ...")])])]},proxy:true},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.Id,class:{ selectedRow: item === _vm.selectedItem }},[_c('td',{staticClass:"text-sm-center"},[_vm._v(" "+_vm._s(item.puestoVacante.areaReporta)+" ")]),_c('td',{staticClass:"text-sm-center"},[_vm._v(" "+_vm._s(item.puestoVacante.nombrePuesto)+" ")]),_c('td',{staticClass:"text-sm-center"},[_vm._v(" "+_vm._s(item.estatus.estatus)+" ")])])}),0)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}},[_c('v-card',[_c('v-card-title',{staticClass:"orange white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Empresa")])]),_c('v-card-text',[_c('br'),_c('h3',{staticClass:"text-lg-center text-sm-center text-md-center"},[_vm._v(" "+_vm._s(_vm.respuesta)+" ")]),_c('br')]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.confirmation = false}}},[_vm._v("OK")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }